.whishlist-btn {
  .carttable__maintable--crossicon {
    background-color: color(primary, base);
    color: #fff;
    font-size: 12px;
    border: none;
    padding: 8px 15px;
    border-radius: 3px;
  }
  .carttable__maintable--deleteicon {
    color: color(primary, base);
  }
}
.wishlist {
  // background-color: #f8f8f8;

  &__leftside {
    margin-top: 62px;
  }
}
.title {
  font-size: 14px;
  font-weight: 600;
  color: #231f1e;
}
.unit-price p {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-bottom: 0;
}
