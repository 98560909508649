.product__similaritems {
  .slick-next {
    right: -6px !important;
  }
}
.product__sliders {
  .slick-next {
    right: -20px !important;
  }
}
.product-outofstock {
  color: #fff;
  width: 40%;
  background-color: rgb(232, 75, 35);
  padding: 10px 0 10px 10px;
}
.price {
  font-size: 20px;
  font-weight: 600;
}
.previous_price {
  font-size: 15px;
  color: #949494;
  font-weight: 400;
  text-decoration: line-through;
}
.new_price {
  font-size: 17px;
  color: #e84b23;
  font-weight: 500;
}
