.carttable {
  .input-group-text {
    background-color: transparent;
    border: none;
  }
  .form-check-input {
    margin-top: 0;
    border-radius: 3px;
    cursor: pointer;
  }
  .form-check-input:checked {
    background-color: color(primary, base);
    border: none;
  }
  &__heading {
    margin-top: 30px;
    display: flex;
    cursor: pointer;
    span {
      font-size: 14px;
      color: #666666;
      font-weight: 400;
    }
    &--deleteicon {
      color: #666;
      margin-right: 10px;
    }
  }
  .carttable__maintable {
    border: 1px solid #d4d7d9;
    border-radius: 3px;
    margin-top: 13px;
    background-color: #fff;

    &--firstrow {
      margin-left: 0;
      margin-right: 0;
      padding: 10px;
      border-bottom: 1px solid #d4d7d9;
      .col-lg-4,
      .col-lg-1,
      .col-lg-2 {
        font-size: 14px;
        font-weight: 600;
        color: #231f1e;
      }
    }

    &--firstdata {
      img {
        height: 50px;
        width: 50px;
        padding: -3px;
        background: #ffffff;
        // box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
        border-radius: 3px;
        margin-right: 12px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        margin-bottom: 0;
      }
      span {
        font-size: 12px;
        color: #666666;
        font-weight: 400;
      }
    }

    .col-lg-1,
    .col-lg-2 {
      .unit,
      .total {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        margin-bottom: 0;
      }
    }
    &--crossicon {
      table {
        background-color: #fff;
        border-color: transparent;
        tbody {
          vertical-align: middle;
          border-style: none !important;
          tr {
            &:nth-child(1) {
              border-bottom: 1px solid #d4d7d9;
              td {
                font-weight: 600;
              }
            }
            th {
              font-size: 14px;
              font-weight: 600;
            }
            td {
              font-size: 14px;
            }
          }
        }
      }
      &--firstdata {
        img {
          height: 50px;
          width: 50px;
          padding: -3px;
          background: #ffffff;
          // box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
          border-radius: 3px;
          margin-right: 12px;
        }
        p {
          font-size: 14px;
          font-weight: 600;
          color: #333333;
          margin-bottom: 0;
        }
        span {
          font-size: 12px;
          color: #666666;
        }
      }
      &--crossicon {
        font-size: 10px;
        color: red;
      }
    }
  }
  &__pagination {
    margin-top: 27px;
    margin-bottom: 136px;
  }
}

@media (max-width: 992px) {
  .carttable .carttable__maintable {
    margin-left: 0px;
  }

  .carttable__maintable--firstrow {
    display: none !important;
  }
  .carttable__maintable--firstdata {
    padding-left: 0 !important;
  }
  .mapping-row {
    margin: 0 15px !important;
    background: #fff;
    padding: 20px;
    border-bottom: 1px solid #e6e6e6;

    .unit {
      display: none;
    }

    .total:before {
      content: "Subtotal : ";
    }

    .inc__dec,
    .total,
    .icon,
    button.carttable__maintable--crossicon {
      margin-left: 1.5rem;
      margin-top: 1rem;
    }
  }
}
