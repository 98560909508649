.product__card {
  //   width: 282px;
  background: #ffffff;
  border-radius: 3px !important;
  border: none !important;
  transition: 0.3s ease-in-out;
  // margin-left: 15px;
  width: 96%;
  &:hover {
    transition: 0.3s ease-in-out;
    // box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  }
  &--image {
    height: 250px !important;
    object-fit: contain;
    padding: 15px;
  }

  .card-body {
    padding: 16px 12px 16px 10px;
  }
  &--body {
    border-top: 1px solid rgba(128, 181, 0, 0.5);

    &--title {
      display: flex;
      justify-content: space-between;
      margin-top: 3px !important;

      &--heading {
        font-size: 16px;
        color: #2f2e41;
        font-weight: 600;
        height: 25px;
      }
      &--icon {
        color: color(primary, base);
        font-size: 20px;
        transition: 0.3s ease-in-out;
        cursor: pointer;
        &:hover {
          transform: scale(1.3);
          transition: 0.3s ease-in-out;
        }
      }
    }

    &--price {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;

      span.price {
        font-size: 16px;
        color: #2f2e41;
        font-weight: 600;
      }
      .newpricediv {
        line-height: 4px;
      }
      .previous_price {
        font-size: 13px;
        color: #949494;
        font-weight: 400;
        text-decoration: line-through;
      }
      .new_price {
        font-size: 14px;
        color: #e84b23;
        font-weight: 500;
        margin-left: 4px;
      }
      &-inout {
        background-color: #004f9a;
        padding: 4px 8px;
        font-size: 14px;
        text-transform: uppercase;
        color: #ffffff;
      }
    }
  }
}
@media (max-width: 1024px) {
  .product__card--body--title--heading{
    font-size: 14px;
  }
}