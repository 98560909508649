.home {
  overflow: hidden;
  .hero {
    &__content {
      padding: 93px 0;
      &--heading {
        font-weight: bold;
        font-size: 36px;
        line-height: 54px;
        letter-spacing: 0.02em;
        color: #231f1e;
      }
      &--para {
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #666666;
      }

      .btn__buy {
        @include btn(#8dc73d);
        padding: 10px 24px;
      }
    }

    &__banner {
      padding: 62px 0;
      &--img {
        max-width: 100%;
        height: 500px;
        object-fit: contain;
        margin: 0 auto;
      }
    }
  }

  .product {
    background-color: #f8f8f8;
    padding: 33px 0 61px 0;

    .red {
      .product__card--body--price-inout {
        background-color: #e84b23;
      }
    }

    &__category {
      position: relative;
      color: #2f2e41;
      font-weight: 600;
      font-size: 20px;
      margin: 0px;
      // margin-bottom: 40px;

      &::before {
        @include beforee(64px);
        top: 33px;
        left: 0px;
      }
    }

    .see__more {
      @include btn(#8dc73d);
      color: #8dc73d;
      border: 1px solid #8dc73d;
      background-color: transparent;
      transition: 0.3s;
    }

    .see__more:hover {
      @include btn(#fff);
      color: #fff;
      background-color: color(primary, base);
    }

    &__row {
      padding-top: 35px;
      padding-bottom: 44px;

      .col-md-3 {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .slick-prev:before,
    .slick-next:before {
      color: #8dc73d;
      opacity: 1;
      font-size: 24px;
    }
  }
}

@media (max-width: 425px) {
  .slick-next {
    right: 7px !important;
  }
}

.mb-13 {
  margin-bottom: 13px;
}

.mb-32 {
  margin-bottom: 32px;
}

@media (max-width: 768px) {
  .hero {
    text-align: center;
    &__banner{
      &--img{
        height: 300px !important;
      }
    }
  }

  .hero__content {
    padding: 40px 0 0 0 !important;
  }

  h2.hero__content--heading.mb-13 {
    font-size: 30px;
    line-height: 1.4;
  }

  .home .product__row.row {
    .col-md-3 {
      margin: 10px 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
  .home .hero__content {
    padding: 40px 0 0 0;

    p.product__category {
      margin-bottom: 40px !important;
    }

  }
  @media (max-width: 576px) {
    .home .product .see__more {
      display: inline-block;
    }
  }
  @media (min-width: 768px) and (max-width: 992px) {
    p.product__card--body--price.card-text {
      display: grid;
    }
  }
}
