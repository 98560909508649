.account__details {
  padding-top: 63px;
  padding-bottom: 156px;
  background-color: #f8f8f8;
}

@media only screen and (max-width: 992px) {
  .account__details {
    .col-lg-3.col-sm-12 {
      margin: 0 0 36px 0;
    }

    .col-lg-4.col-sm-12 {
      margin-bottom: 50px;
    }
  }
}
