.product {
  padding: 37px 0 54px 0;

  .new__items {
    position: relative;
    font-size: 22px;
    color: #231f1e;
    margin-bottom: 50px;
    font-family: "Lato", sans-serif;

    &::before {
      @include beforee(84px);
      top: 33px;
      left: 0px;
    }
  }

  &__row {
    padding-top: 0px;
    padding-bottom: 60px;
  }

  .slick-prev:before,
  .slick-next:before {
    color: #8dc73d;
    opacity: 1;
    font-size: 24px;
  }
}

.red {
  .product__card--body--price-inout {
    background-color: #e84b23;
  }
}
