
  .ordercomplete{
    text-align: center;
    img{
        width: 20%;
        margin: 60px auto 20px auto;
        text-align: center;
        display: flex;
    }
    a{
        margin-bottom: 60px;
        display: inline-block;
    }
  }