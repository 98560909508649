.incredecre {
  &__inc-dec {
    &--quantity {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 600;
    }
    &--button {
      tr {
        background: linear-gradient(0deg, #8dc73d, #8dc73d);
      }
      &--dec {
      
        color: #fff;
        width: 16px;
        height: 18px;
        text-align: center;
        cursor: pointer;
      }
      .minus {
        border-radius: 2px 0px 0px 2px;
        width: 30px;
        height: 30px;
      }
      .plus {
        border-radius: 0px 2px 2px 0px;
        width: 30px;
        height: 30px;
      }
      &--num {
        text-align: center;
        span{
          background-color: #fff;
          padding: 3px 10px 3px 10px;
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
