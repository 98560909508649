.subdropdown{
    .navar-dropdown-li{
        width: 93%;
        // box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
        a{
            color: #000 !important;
        }
    }
}
.small-navitem{
    font-weight: 600;
}