.clear__cart {
  margin-top: 55px;
  margin-bottom: 95px;
  text-align: center;

  &--title {
    color: #3f2803;
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 60px;
  }
}
