.product {
  background-color: #f8f8f8;
  padding: 64px 0;
  &__rows {
    padding-bottom: 64px;
  }
  &__image {
    img {
      width: 90%;
      margin: 0 auto 80px auto;
      object-fit: contain;
      height: 200px;
      border-radius: 3;
    }
  }
  &__sliders {
    background-color: #fff;
    padding: 10px;
    .slick-next:before,
    .slick-prev:before {
      color: color(primary, base) !important;
    }
    &--image {
      height: 90px;
      width: 90px;
      padding: 8px 0 0 3px;
      object-fit: cover;
    }
  }
  &__heading {
    border-bottom: 1px solid #d4d7d9;
    padding-bottom: 10px;
    &--main {
      font-size: 24px;
      color: #231f1e;
      font-weight: 600;
      margin-bottom: -10px;
    }
    &--span {
      color: #666666;
      font-size: 14px;
      line-height: 17px;
    }
  }
  &__price {
    padding-top: 10px;
    &--totalprice {
      font-size: 16px;
      font-weight: 700;
      color: #231f1e;
    }
    &--stock {
      color: #004f9a;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      margin-top: 6px;
      text-transform: uppercase;
    }
  }
  &__inc-dec {
    &--quantity {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 600;
      margin-right: 50px;
    }
  }
  &__btns {
    margin-top: 40px;
    // border-bottom: 1px solid #d4d7d9;
    padding-bottom: 30px;

    &--addtocart {
      @include btn(#8dc73d);
      padding: 13px 16px;
      &:hover {
        color: #fff;
      }
    }
    &--buynow {
      @include btn(#004f9a);
      padding: 13px 16px;
      margin-left: 27px;
      &:hover {
        color: #fff;
      }
    }
  }
  &__lists {
    margin-top: 20px;
    p {
      color: #231f1e;
      font-size: 18px;
      font-weight: 600;
    }
    ul {
      list-style: disc;
      li {
        margin: 7px 0;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
  &__description {
    background-color: #fff;
    padding: 45px 0;
    &--heading {
      font-size: 22px;
      font-weight: 600;
      position: relative;
      &::before {
        @include beforee(86px);
      }
    }
    &--descriptionpara {
      margin-top: 60px;
      font-size: 18px;
      line-height: 33px;
      color: #231f1e;
      font-weight: 400;
    }
  }
  &__similaritems {
    padding-top: 64px;
    &--heading {
      font-size: 22px;
      font-weight: 600;
      position: relative;
      &::before {
        @include beforee(80px);
      }
    }
  }
}

@media (max-width: 768px) {
  .product__sliders {
    img {
      margin: 0 auto;
    }
    .slick-next {
      right: 0;
    }
    .slick-prev {
      left: -4px;
    }
  }
  .product{
    margin-top: 30px;
  }
  .toast {
    position: fixed;
    top: 85%;
    left: 2% !important;
    width: 300px !important;
    z-index: 99;
    border-radius: 10px;
    border: none;
    .toast-body {
      background-color: #004f9a !important;
      border-radius: 4px;
      padding: 12px 15px;
      font-size: 13px;
    }
  }
}
.toast {
  position: fixed;
  top: 85%;
  left: 71%;
  width: 400px !important;
  z-index: 99;
  border-radius: 10px;
  border: none;
  .toast-body {
    background-color: #004f9a !important;
    border-radius: 4px;
    padding: 15px 20px;
    font-size: 16px;
  }
  .checkicon {
    font-size: 26px;
    background-color: #fff;
    border-radius: 50%;
    padding: 3px;
    color: #004f9a;
    margin-left: 10px;
  }
}
