.accountsidenav {
  &__wrapper {
    background: #ffffff;
    border: 1px solid rgba(212, 215, 217, 0.7);
    border-radius: 3px;
    padding: 10px 22px;
    ul {
        padding-left: 0;
        margin-bottom: 0;
      li {
          line-height: 30px;
        a {
          font-size: 14px;
          color: #9b9595;
          
        }
      }
    }
  }
}
.active-link.active{
  color: color(primary,base) !important;
  font-weight: 500;
  border-bottom: 1px solid color(primary,base);
}
