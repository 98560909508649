.logo {
  img {
    width: 90px;
    height: 90px;
    object-fit: cover;
  }
}
.imput-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid color(primary, base);
  border-radius: $radius-buttons;
  padding: 10px;
  width: 93%;
  input {
    border: none;
    padding: 0;
    &:focus {
      box-shadow: none;
    }
    &::placeholder {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #555555;
      font-family: Lato, sans-serif;
    }
  }
  .search-icon {
    color: color(primary, base);
    font-size: 20px;
    font-weight: 700;
  }
}
.navbarright-wrapper {
  span {
    font-weight: 600;
    font-size: 18px;
    color: #231f1e;
    margin-bottom: 0;
    .navbar-right-icon {
      color: color(primary, base);
      margin-bottom: 8px;
      font-size: 25px;
      transition: 0.3s ease-in-out;
      position: relative;
      &:hover {
        transform: scale(1.2);
        transition: 0.3s ease-in-out;
      }
    }
    .navbar-right-icon2 {
      color: color(primary, base);
      font-size: 30px;
    }
    .icon-wish {
      margin-left: 10px;
    }
    span {
      margin: 0 15px;
    }
  }
  &:nth-child(1) {
    padding-right: 25px;
  }

  .navbar-shopping-card-sup {
    color: #fff;
    background-color: #e92525;
    font-size: 18px;
    top: -0.8em;
    border-radius: 50%;
    padding: 1px 6px;
  }
}
.second-nav-wrapper {
  background-color: color(primary, base);
  width: 100%;
  padding: 8px 0;
  .categories {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .navbar-left {
      color: #fff;
      display: flex;
      align-items: center;
      cursor: pointer;
      .dashboard-icon {
        font-size: 20px;
      }
      .downarrow-icon {
        font-size: 20px;
      }

      span {
        margin: 0 15px;
        font-size: 18px;
        text-transform: uppercase;
      }
    }
    .about-paicho {
      margin-right: 380px;
      color: #fff;
      font-size: 18px;
      font-weight: 500;
    }
    .navbar-dropdown {
      top: 33px;
      left: -30px;
      position: absolute;
      z-index: 9999;
    }
    .navar-dropdown-ul {
      padding-top: 6px;
      border-radius: 0;
      background: transparent;
      border: none;
      position: relative;

      .navar-dropdown-li {
        width: 291px;
        height: 45px;
        font-size: 16px;
        padding-top: 10px;
        background: #ffffff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        padding-left: 25px;
        .navbardropdown-flex {
          display: flex;
          justify-content: space-between;
        }
        a {
          color: #231f1e;
        }
        .dropdown-submenu {
          display: none;
          li {
            width: 291px;
            height: 45px;
            font-size: 16px;
            background: #ffffff;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            padding: 10px 10px;
            a {
              color: #231f1e;
            }
          }
        }
        &:hover .dropdown-submenu {
          display: block;
          position: absolute;
          left: 90%;
          top: 6px;
        }
        .main-category {
          font-weight: 600;
          color: #000;
          &:hover {
            color: color(primary, base);
            font-weight: 600;
          }
        }
        .dropdown-item-icon {
          // position: absolute;
          // left: 85%;
          color: #000;
          font-size: 23px;
          // top: 25%;
        }
      }
    }
    .dropdown-num {
      margin-left: 90px;
    }
    .delivery-num {
      display: -webkit-inline-box;
      color: #fff;
      .call-icon {
        margin-right: 10px;
        margin-top: 5px;
        font-size: 20px;
      }
      p {
        margin-bottom: 0;
      }
    }
  }
}

.account-btn {
  position: absolute;
  top: 16%;
  right: 10%;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  padding: 16px 19px;
  z-index: 99;
  .account-signin {
    @include btn(#8dc73d);
    font-size: 16px;
    padding: 6px 56px;
    margin-top: 20px;
    display: block;
  }
  .account-create {
    font-size: 16px;
    padding: 6px 25px;
    color: color(primary, base);
    border: 1px solid #8dc73d;
    margin: 20px 0;
    display: block;
    border-radius: 3px;
  }
  .account-accdetails {
    // border-top: 1px solid rgba(35, 31, 30, 0.4);
    text-align: center;
    display: block;
    padding-top: 10px;
    text-decoration: underline !important;
    font-size: 14px;
    color: #004f9a;
  }
  .account-wishlistdetails {
    text-align: center;
    display: block;
    padding-top: 10px;
    text-decoration: underline !important;
    font-size: 14px;
    color: #004f9a;
    margin-bottom: 10px;
  }
}
.modal-content {
  padding: 40px 50px;
  width: 120% !important;
  .modal-header {
    padding: 0;
    border-bottom: none;
    .btn-close {
      padding: 0.5rem 0.5rem;
      margin: -4.5rem -2rem -0.5rem auto;
    }
  }
  .modal-title {
    p {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: -15px;
    }
    span {
      color: #666666;
      font-size: 12px;
      font-weight: 400;
    }
  }
  .modal-dialog {
    width: 600px !important;
  }
  .modal-body {
    padding: 0;
    position: relative;

    .sign-in-btn {
      @include btn(#8dc73d);
      margin-top: 25px;
      padding: 11px 47px;
    }

    .arrow-left {
      width: 0;
      height: 0;
      border-top: 60px solid transparent;
      border-bottom: 60px solid transparent;
      border-left: 60px solid #8dc73d;
      transform: rotate(-44.8deg);
      right: -60px;
      top: -79.5px;
      position: absolute;
    }

    .arrow-right {
      width: 0;
      height: 0;
      border-top: 60px solid transparent;
      border-bottom: 60px solid transparent;
      border-left: 60px solid #015aaa;
      transform: rotate(-225deg);
      position: absolute;
      left: -59.5px;
      top: 240px;
    }

    .cart__modal {
      text-align: center;
      margin: 60px 0;

      &--ask {
        margin-bottom: 44px;
      }

      .yes-btn {
        @include btn(#e84b23);
        padding: 8px 47px;
      }
      .no-btn {
        @include btn(#8dc73d);
        padding: 8px 47px;
        margin-top: 22px;
      }
    }

    .forget-password {
      display: block;
      margin-top: 20px;
      font-size: 12px;
    }

    p.or {
      position: relative;
      text-align: center;
      margin-top: 15px;
      margin-bottom: 0;
      font-size: 14px;
      color: #666666;

      &:before {
        content: "";
        position: absolute;
        top: 14px;
        left: 2px;
        background-color: #babfc3;
        width: 230px;
        height: 1px;
      }
      &::after {
        content: "";
        position: absolute;
        top: 14px;
        left: 264px;
        background-color: #babfc3;
        width: 235px;
        height: 1px;
      }
    }
    .signin-socialmediaicon {
      text-align: center;
      margin-top: 20px;
      img {
        &:nth-child(1) {
          margin-right: 15px;
        }
      }

      span {
        color: #666666;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .dont-haveacc {
      margin-top: 20px;
      font-size: 12px;
      text-align: center;
      margin-bottom: 0;
      color: #666666;
      span {
        color: #004f9a;
        cursor: pointer;
        font-weight: 500;
      }
    }
    .modal-dialog {
      width: 600px !important;
    }
    .modal-body {
      padding: 0;
      .sign-in-btn {
        @include btn(#8dc73d);
        margin-top: 25px;
        padding: 11px 47px;
      }
      .forget-password {
        display: block;
        margin-top: 20px;
        font-size: 12px;
      }
      p.or {
        position: relative;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 0;
        font-size: 14px;
        color: #666666;
        &::before {
          content: "";
          position: absolute;
          top: 14px;
          left: 2px;
          background-color: #babfc3;
          width: 230px;
          height: 1px;
        }
        &::after {
          content: "";
          position: absolute;
          top: 14px;
          left: 264px;
          background-color: #babfc3;
          width: 235px;
          height: 1px;
        }
      }
      .signin-socialmediaicon {
        text-align: center;
        margin-top: 20px;
        img {
          &:nth-child(1) {
            margin-right: 15px;
          }
        }
      }
      .dont-haveacc {
        margin-top: 20px;
        font-size: 12px;
        text-align: center;
        margin-bottom: 0;
        color: #666666;
      }
    }
  }
  .createaccount {
    display: flex;
    align-items: center;
    margin-top: 25px;
    p {
      font-size: 12px;
      color: #666666;
      margin-bottom: 0;
    }
    .input-group-text {
      background-color: transparent;
      border: none;
      padding-left: 0;
    }
    .form-check-input:checked {
      background-color: #8dc73d;
      border: none;
    }
  }
  .already-have-acc {
    margin-top: 20px;
    font-size: 12px;
    margin-bottom: 0;
    color: #666666;
  }
}
// Mobile Navbar

.mobile-navbar {
  .accordion {
    width: 100%;
    position: absolute;
    top: 90px;
    .accordion-button {
      flex-direction: column;
      padding: 10px 0;
      font-size: 15px;
    }
    .accordion-button::after {
      position: absolute;
      right: 20%;
    }
    .accordion-title {
      color: #000;
    }
  }
  display: none;
  position: relative;
  z-index: 999;
  .mobile-navbar-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .hambergur-menu {
      font-size: 50px;
      border-radius: 5px;
      border: 1px solid #8dc73d;
      padding: 10px;
      color: color(primary, base);
    }
    .navbar-dropdown {
      position: absolute;
      box-shadow: 1px 0px 7px -2px rgba(0, 0, 0, 0.75);
      background-color: #fff;
      width: 100%;
      top: 90px;
      padding: 20px 0;
    }
    .navar-dropdown-ul {
      padding-left: 0;
      margin-bottom: 0;
      .dropdown-submenu {
        padding-left: 20px;
      }
      li {
        padding: 5px 10px;
        margin: 5px 0;
        position: relative;
        a {
          color: #231f1e;
        }
        &:nth-last-child(1) {
          border-bottom: none;
        }
      }
    }
    .dropdown-item-icon {
      position: absolute;
      left: 90%;
      font-size: 30px;
      transform: rotate(90deg);
    }
  }
  .navar-subdropdown {
    margin-left: 20px;
  }
}

// Responsive
@media (max-width: 1024px) {
  .account-btn {
    right: 3%;
    top: 15%;
  }
  .second-nav-wrapper .categories .about-paicho{
    margin-right: 30px;
  }
  
}

@media (max-width: 1200px) and (min-width: 1024px) {
  .navbarright-wrapper span span {
    margin: 0 5px;
  }
  .navbarright-wrapper:nth-child(1) {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 992px) {
  .navbarright-wrapper:nth-child(1) {
    border-right: none;
  }
  .second-nav-wrapper .categories .about-paicho {
    margin-right: 0px !important;
  }
  // .dropdown-num{
  //   margin-left: 60px !important;
  // }
}

@media (min-width: 768px) and (max-width: 992px) {
  .btn__buy {
    margin: 10px 0;
  }
  // .account-btn {
  //   top: 20%;
  // }
}

@media only screen and (max-width: 768px) {
  .account-btn {
    right: 6%;
    top: 40%;
  }
  //   top: 23%;
  // }
  .second-nav-wrapper .categories {
    display: none !important;
  }
  .imput-wrapper {
    width: 100%;
  }
  .dropdown {
    margin-left: -10px;
  }
  .dropdown-num {
    margin-top: 30px;
  }
  .modal-content {
    width: 100% !important;
  }
  .modal-content .modal-body .or::before,
  .modal-content .modal-body .or::after {
    display: none;
  }
  .mobile-navbar {
    display: block;
  }
  .first-navbar {
    .logo {
      img {
        display: none;
      }
    }
  }
  .second-nav-wrapper {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .account-btn {
    // top: 80%;
    top: 46%;
    // @media (max-width: 767px) and (min-width: 576px) {
    //   .account-btn {
    //     top: 53%;
    //   }
  }

  @media only screen and (max-width: 576px) {
    .account-btn {
      top: 40%;
    }
  }

  @media only screen and (max-width: 441px) {
    .modal-content .modal-body .arrow-right {
      top: 286px;
    }
  }

  @media only screen and (max-width: 425px) {
    .modal-content {
      width: 92%;
    }
    //   .account-btn {
    //     top: 47%;
    // }
  }

  @media only screen and (max-width: 325px) {
    .modal-content .modal-body .sign-in-btn {
      padding: 11px 45px;
    }
  }
}
.subscriber_name {
  display: contents;
  font-weight: 600;
}
.account-logout {
  background-color: #8dc73d;
  color: #fff;
  margin-left: 10px;
  padding: 5px 20px;
  border-radius: 3px;
  font-size: 15px;
  &:hover {
    color: #fff;
  }
}
.register-error{
  color: red;
  font-size: 14px;
  margin-bottom:0;
}
.account-btn-close{
  color: #fff;
  /* font-size: 5px; */
  position: absolute;
  right: 6%;
  top: 6%;
  background: #8dc73d;
  padding: 5px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
}
