.category-wrapper{
    background-color: #fff;
    padding: 20px 16px 38px 16px;
    border-radius: 3px;
    ul{
       padding: 17px 0;
    border-bottom: 1px solid rgba(212, 215, 217, 0.5);
    li{
        font-size: 14px;
        color: #2F2E41;
        font-weight: 600;
    }
    .input-group-text {
        background: transparent;
       border: none;
    }
    .form-check-input{
        margin-left: -12px;
        border: 1px solid #8dc73d;
        cursor: pointer;
    }
    .form-check-input:checked{
        background-color: #8dc73d ;
       
    }
}

&__filter,&__subcategory,&__price{
    font-size: 18px;
    font-weight: 600;
    color: #2F2E41;
    padding-bottom: 17px;
    border-bottom: 1px solid rgba(212, 215, 217, 0.5);
}
&__filter{
    font-size: 23px;
    font-weight: 600;
    padding-bottom: 17px;
    margin-bottom: 0;
}
&__subcategory{
    border-bottom: none;
    margin-bottom: 0;
}
&__clearfilter{
    font-size: 14px;
    font-weight: 600;
    color: #E84B23;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 5px;
}
&__minmax{
    padding-top: 7px;
    p{
        font-size: 13px;
        color: #2F2E41;
        font-weight: 600;
        margin-bottom: 5px;
    }
    input{
        width: 70%;
        border: 1px solid #BABFC3;
        border-radius: 3px;
    }
}
&__buttons{
    margin-top: 25px;
    &--clear{
        color:#E84B23 ;
        padding: 10px 24px;
        border: 1px solid #E84B23;
        font-size: 14px;
        font-weight: 600;
        border-radius: 3px;
        background: #fff;
    }
    &--apply{
        border: none;
        color:#fff ;
        padding: 10px 24px;
        font-size: 14px;
        font-weight: 600;
        border-radius: 3px;
        background: #8DC73D;
    }
}
}
